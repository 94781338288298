.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: content-box;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
  background: #fff;
  position: relative;
}

.ReactTable .rt-th {
  position: initial;
}

.ReactTable .tableHeader:focus {
  outline: none;
}

.ReactTable .rt-th:focus {
  outline: none;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Custom CSS for our tables */
.tableHeader {
  position: relative;

  display: flex;
  align-items: center;

  height: 72px;

  margin-bottom: 4px;
  padding: 0 40px 0 15px;

  color: #fff;
  font-family: 'OpenSans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;

  background: rgb(73, 83, 87);

  border-right: 1px solid #fff;
}

/* IE11 Hack */
@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, .-headerGroups .tableHeader { z-index: 500 }
}

.tableHeader:not(.sortable) {
  justify-content: center;

  padding: 0 17px;

  text-align: center;
}

.tableCellMain,
.tableCell {
  position: relative;

  height: 100%;

  padding: 5px 17px;

  color: rgb(73, 83, 87);
  font-family: 'OpenSans', sans-serif;
  font-size: 14px;
  letter-spacing: -0.002em;
  line-height: 20px;

  background: #fff;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableCellMain button,
.tableCell button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100% !important;
}

.tableCellMain.overflowing:hover,
.tableCell.overflowing:hover,
.tableCellMain > .overflowing:hover,
.tableCell > .overflowing:hover {
  position: absolute;
  overflow: visible;
  width: auto;
  z-index: 10;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}

.rt-td:last-child .tableCellMain.overflowing:hover, 
.rt-td:last-child .tableCell.overflowing:hover {
  text-align: right;
  right: 0;
}

.tableCell.currency span.value {
  display: inline-block;
}

.tableCell.nullValue span.value {
  text-align: center;
}

.tableCell.currency span.dollarSignText {
  display: none;
}

.tableCell.currency.dollarSign span.dollarSignText,
.tableCell.dollarSign.footer span.dollarSignText{
  position: absolute;
  clear: both;
  display: inline-block;
}

.tableCellMain.footer,
.tableCell.footer {
  margin: 0 0 0 auto;
  padding-top: 20px;
  padding-bottom: 25px;

  font-weight: bold;
  text-transform: uppercase;
}

.tableCell.footer {
  max-width: 130px;
}

.tableCell {
  text-align: right;
}
.tableCell.subtotal,
.tableCellMain.subtotal {
  margin: 0 0 0 auto;
  padding-top: 2px;
  padding-bottom: 15px;
}

.tableCell.subtotal::after {
  content: '';
  position: absolute;
  right: 15%;
  left: 15px;
  top: 0px;
  width: 105px;
  height: 1px;
  background: #495357;
}

.tableCell.bold {
  font-weight: bold;
}

.tableCell.underline::after {
  content: '';
  position: absolute;
  right: 15%;
  left: 15px;
  bottom: 4px;
  z-index: 1;
  width: 105px;
  height: 1px;
  background: #495357;
}

.cutoff::after {
  content: '';
  position: absolute;
  right: 15%;
  left: 15px;
  top: 4px;
  z-index: 1;
  width: 105px;
  height: 1px;
  background: #495357;
}

.tableCellMain.subtotal,
.tableCellMain.footerText {
  padding-left: 50px;
}

.tableCell.currency,
.tableCell.percentage {
  max-width: 130px;

  margin: 0 0 0 auto;
}

.tableHeader.sortable {
  cursor: pointer;

  padding: 0 50px 0 17px;
}

.sortable::after {
  content: '';
  position: absolute;
  top: calc(50% - 2.5px);
  right: 20px;
  width: 0;
  height: 0;
  border-bottom: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(255, 255, 255);
  clear: both;
}

.sortable.active::after {
  border-top: 5px solid rgb(220, 158, 29);
}

.sortable.asc::after {
  border-bottom: 5px solid rgb(255, 255, 255);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 0;
}

.sortable.active.asc::after {
  border-bottom: 5px solid rgb(220, 158, 29);
}

.tableLink {
  background: none;
  color: #0077b5;
}

.center {
  text-align: center;
}

.indentLeft {
  margin-left: 30px;
}

.tableCell.single,
.tableCellMain.single {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tableCell.first,
.tableCellMain.first {
  padding-top: 10px;
}

.empty-table {
  height: 340px;
}

.empty-table .rt-tfoot {
  display: none;
}

.idf-returns .rt-table .rt-thead.-headerGroups {
  position: absolute;
}
